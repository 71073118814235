<template>
  <div class="row">
    <div
      class="col"
      :class="'col-' + 100 / items.length"
      v-for="(item, index) in items"
    >
      <div class="form__label-wrap" @click="$emit('input', item.value)">
        <input
          class="form__radio"
          type="radio"
          :id="name + index"
          :name="name"
          :checked="item.value === value"
        />
        <label class="form__label" :class="{ 'has-error': error }" :for="name + index">
          {{ item.title }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'radio-buttons-box',
  props: ['items', 'value', 'name', 'error'],
}
</script>
