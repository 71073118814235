<template>
  <section class="auction__section">
    <div class="auction__lots-header">
      <div class="row">
        <div class="col col-25">
          <div class="input__wrap" v-if="enableSearch">
            <input
              class="input"
              type="text"
              :placeholder="$t('auctions.lots_search')"
              v-model="query"
            />
            <div class="input__inner">
              <svg
                v-svg
                symbol="icon-search"
                size="15 15"
                class="search__button-icon"
              ></svg>
            </div>
          </div>
        </div>
        <div class="col col-75">
          <div class="auction__lots-navigation">
            <div class="auction__lots-navigation-controller"></div>
            <div class="auction__lots-navigation-info">
              <div class="auction__lots-navigation-page">
                {{ soldLotsCount() }} / {{ auction.lots.length }}
              </div>
              <div class="auction__lots-navigation-sold">
                {{ $t('live.lots_sold') }} {{ getAuctionProgress() }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="auction__lots-body">
      <div class="carousel">
        <a
          class="carousel__button carousel__button--left"
          @click="$refs.current.prev()"
        >
          <svg
            width="12"
            height="21"
            viewBox="0 0 12 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11 20L2 10.5L11 1" stroke="#363636" stroke-width="2" />
          </svg>
        </a>
        <a
          class="carousel__button carousel__button--right"
          @click="getNewLotsEvent"
        >
          <svg
            width="12"
            height="21"
            viewBox="0 0 12 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L10 10.5L1 20" stroke="#363636" stroke-width="2" />
          </svg>
        </a>
        <VueSlickCarousel ref="current" v-bind="settings" @afterChange="afterChangeEvent">
          <live-streaming-lots-item
            class="col "
            v-for="lot in filteredResults"
            :lot="lot"
            :auction="auction"
            :key="lot.id"
            @reopenned="$emit('reopenned', lot)"
            @clicked="$emit('lot-selected', lot)"
          />
        </VueSlickCarousel>
      </div>
    </div>
  </section>
</template>

<script>
import LiveStreamingLotsItem from './LiveStreamingLotsItem'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
export default {
  components: {
    LiveStreamingLotsItem,
    VueSlickCarousel
  },
  name: 'live-streaming-lots-list',
  props: {
    auction: {
      type: Object,
      default: () => {
        return {}
      },
    },
    enableSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      query: '',
      settings: {
        dots: false,
        dotsClass: 'slick-dots',
        arrows: false,
        infinite: false,
        initialSlide: 1,
        slidesToShow: 4,
        slidesToScroll: 1,
        draggable: false,
        swipe: false,
        touchMove: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              vertical: true,
              slidesToShow: 2,
            },
          },
        ],
      },
    }
  },
  methods: {
    getNewLotsEvent(){
      this.$emit('getNewLotsEvent')
      this.$refs.current.next()
    },
    afterChangeEvent(page)
    {
      this.$emit('afterPageChangeEvent', page)
    },
    soldLotsCount() {
      return this.auction.lots.filter((lot) => !lot.product.count).length
    },
    getAuctionProgress() {
      return parseInt((this.soldLotsCount() / this.auction.lots.length) * 100)
    },
  },
  computed: {
    filteredResults() {
      return this.auction.lots.filter(
        (lot) =>
          lot.product.translation.name
            .toLowerCase()
            .indexOf(this.query.toLowerCase()) !== -1
      )
    },
  },
}
</script>

<style scoped>

.carousel {
  position: relative;
}

.carousel__button {
  position: absolute;
  top: 20%;
}

.carousel__button--left {
  left: -20px;
}

.carousel__button--right {
  right: -20px;
}

@media (max-width: 1024px) {
  .carousel__button--left {
    left: 50%;
    transform: translateX(-50%) rotateZ(90deg);
    top: -30px;
  }

  .carousel__button--right {
    display: none;
  }
}
</style>
