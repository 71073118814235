<template>
  <section class="ventes">
    <div class="loader placeholder" v-if="!isLoaded">
      <img src="/img/loader.svg" alt="Loader" />
    </div>

    <template v-if="isLoaded">
      <auction-header :auction="auction"></auction-header>
      <div class="action-bar">
        <a
          v-if="needToShowLiveStreamingButton()"
          class="button live-button"
          :href="getLiveAuctionRedirectData().path"
        >
          {{ $t('auctions.item.button_live') }}
        </a>
        <router-link
          v-if="needToShowOnlineBiddingButton()"
          class="button live-button"
          :to="{
            name: 'app.client_auction_bidding',
            params: { slug: auction.slug },
          }"
        >
          {{ $t('auctions.item.button_bid') }}
        </router-link>
        <div class="layout-switch">
          {{ $t('auctions.viewing') }}
          <a @click="changeFormat('grid')" class="grid"
          >{{ $t('auctions.grid') }} <i class="fas fa-border-all"></i
          ></a>
          <a @click="changeFormat('catalogue')"
          >{{ $t('auctions.catalog2') }} <i class="fas fa-th-list"></i
          ></a>
        </div>
      </div>
      <div class="row">
        <auction-sidebar
          :auction="auction"
          :is-current="isCurrent"
          :auction-filter="filter"
          @filteredLots="auction.lots = $event"
          @showConditions="openConditions"
        ></auction-sidebar>

        <div class="col col-75" v-if="conditionsOpened">
          <div class="conditions">
            <div class="close">
              <a @click="closeConditions">
                <svg
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.9179 1.41L13.514 0L7.94821 5.59L2.38241 0L0.978516 1.41L6.54432 7L0.978516 12.59L2.38241 14L7.94821 8.41L13.514 14L14.9179 12.59L9.35211 7L14.9179 1.41Z"
                    fill="#363636"
                  />
                </svg>
              </a>
            </div>
            <div v-for="condition in auction.data.conditions">
              <h4>{{ condition.title }}</h4>
              <p>{{ condition.content }}</p>
            </div>
          </div>
        </div>

        <div
          class="col col-75"
          v-if="format === 'catalogue' && !conditionsOpened"
        >
          <div class="right-wrapper">
            <div class="col col-33">
              <biddable-lot-item
                v-for="product in auction.lots.slice(0, 1)"
                :product="product.product"
                :auction="auction"
                :key="product.id"
                @update="reloadLots"
              >
              </biddable-lot-item>
            </div>
            <div class="col col-66 cover-image">
              <div
                class="video-wrapper"
                v-if="auction.data.video_url && auction.data.video_url !== ''"
              >
                <iframe
                  :src="auction.data.video_url"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <a v-else-if="auction.magazine_url && auction.magazine_url !== ''" :href="auction.magazine_url" target="_blank">
                <img
                  :src="this.$http.getBackendUrl() + magazineImage[0].link"
                  :alt="auction.translation.title"
                />
              </a>
              <img
                v-else
                :src="this.$http.getBackendUrl() + magazineImage[0].link"
                :alt="auction.translation.title"
              />
            </div>
          </div>
          <div class="lots__list">
            <div class="row">
              <div class="col additional__items">
                <biddable-lot-item
                  v-for="product in auction.lots.slice(1)"
                  :product="product.product"
                  :auction="auction"
                  :key="product.id"
                  @update="reloadLots"
                  class="col col-33"
                >
                </biddable-lot-item>
              </div>
            </div>
          </div>
          <next-auction-section
            v-if="auction.slug === 'bibliotheque-francois-mitterrand-1-2018'"
          />
        </div>

        <div class="col col-75" v-if="format === 'grid' && !conditionsOpened">
          <div class="row">
            <biddable-lot-item
              v-for="product in auction.lots"
              :product="product.product"
              :auction="auction"
              :key="product.id"
              @update="reloadLots"
              class="col col-33"
            >
            </biddable-lot-item>
          </div>
        </div>
      </div>
      <infinite-loading
        ref="loader"
        @infinite="loadMore(arguments[0])"
        spinner="waveDots"
        :distance="1000"
      >
        <span slot="no-more"></span>
      </infinite-loading>

    </template>
  </section>
</template>

<script>
import { isEmpty } from 'lodash'
import AuctionStatusMixin from './mixins/auctionStatus.mixin'
import NextAuctionSection from './../live_streaming/common/next_auction_section/NextAuctionSection'
import BiddableLotItem from '@/components/auctions/common/BiddableLotItem'
import AuctionHeader from '@/components/auctions/common/AuctionHeader'
import AuctionSidebar from '@/components/auctions/common/AuctionSidebar'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  components: {
    AuctionSidebar,
    AuctionHeader,
    BiddableLotItem,
    NextAuctionSection,
    InfiniteLoading,
  },
  mixins: [AuctionStatusMixin],
  name: 'AuctionDetails',
  metaInfo() {
    return {
      title: this.auction.translation.title,
    }
  },
  data() {
    return {
      isLoaded: false,
      format: 'catalogue',
      conditionsOpened: false,
      auction: {
        translation: {
          title: '',
          description: '',
        },
        location: '',
        data: {
          publications: [],
          conditions: [],
          catalogs_count: 0,
        },
        media: [{ link: '' }],
        lots: [],
        employees: [],
      },
      filter: {
        active: '',
        min: 0,
        max: 0,
        artist: '',
        estimations: [0, 0],
        currentEstimations: [0, 0],
        currentMin: 0,
        currentMax: 0,
        query: '',
        lots: [],
      },
      isCurrent: false,
      sliderInitItems: 13,
      sliderOffset: 0,

    }
  },
  created: function () {
    this.initAuction()
  },
  computed: {
    magazineImage() {
      const magazineImages = this.auction.media.filter(
        (mediaItem) => mediaItem.type === 'magazine'
      )

      if (magazineImages.length) {
        return magazineImages
      }

      return this.auction.media
    },
  },
  methods: {
    async loadMore($state)
    {
      await this.getNewLots($state)
    },
    getAuction(startedAt) {
      return this.$t('auctions.auction_date', {
        date: this.moment(startedAt).format('dddd DD MMMM YYYY'),
        to: this.moment(startedAt).format('LT'),
      })
    },
    initAuction() {
      const self = this

      this.$api.getAuction(this.$route.params.slug, this.sliderInitItems, 0).then((response) => {
        self.auction = response.data
        if (self.auction.finished) {
          self.auction.lots = response.data.lots.filter(
            (lot) => lot.product.price
          )
        }

        if (isEmpty(self.auction.data.contact)) {
          self.auction.data.contact = {
            name: '',
            phone: '',
            email: '',
          }
        }
        self.isLoaded = true
        self.filter.lots = self.auction.lots

        self.filter.estimations[0] = self.filter.min = Math.min.apply(
          Math,
          self.auction.lots.map((o) => o.product.min_estimation)
        )
        self.filter.estimations[1] = self.filter.max = Math.max.apply(
          Math,
          self.auction.lots.map((o) => o.product.max_estimation)
        )

        if (
          self.auction.started_at &&
          this.moment(this.auction.started_at).isBefore() &&
          this.moment(this.auction.finished_at).isAfter()
        ) {
          self.filter.currentEstimations[0] = self.filter.currentMin =
            self.filter.estimations[0]
          self.filter.currentEstimations[1] = self.filter.currentMax =
            self.filter.estimations[1]
          self.isCurrent = true
        }
      })
    },
    getNewLots(state)
    {
      this.refreshLotsPaginate(10, this.sliderOffset + this.sliderInitItems, state)
    },
    async refreshLotsPaginate(limit, offset, state = null)
    {
      const self = this
      await this.$api.getAuction(this.$route.params.slug, limit, offset).then((response) => {
        const tempLots = self.auction.lots
        self.auction = response.data
        self.auction.lots = tempLots.concat(response.data.lots)
        self.sliderInitItems = self.auction.lots.length
        if (self.auction.finished) {
          self.auction.lots = response.data.lots.filter(
            (lot) => lot.product.price
          )
        }

        if (isEmpty(self.auction.data.contact)) {
          self.auction.data.contact = {
            name: '',
            phone: '',
            email: '',
          }
        }
        self.isLoaded = true
        self.filter.lots = self.auction.lots
        self.filter.estimations[0] = self.filter.min = Math.min.apply(
          Math,
          self.auction.lots.map((o) => o.product.min_estimation)
        )
        self.filter.estimations[1] = self.filter.max = Math.max.apply(
          Math,
          self.auction.lots.map((o) => o.product.max_estimation)
        )

        if (
          self.auction.started_at &&
          this.moment(this.auction.started_at).isBefore() &&
          this.moment(this.auction.finished_at).isAfter()
        ) {
          self.filter.currentEstimations[0] = self.filter.currentMin =
            self.filter.estimations[0]
          self.filter.currentEstimations[1] = self.filter.currentMax =
            self.filter.estimations[1]
          self.isCurrent = true
        }
        if (state) {
          if (isEmpty(response.data.lots)) {
            state.complete()
          } else {
            state.loaded()
          }
        }

      })
    },
    changeFormat(format) {
      this.format = format
      window.smoothscroll()
    },
    refreshLots() {
      this.filter.active = ''
      this.filter.artist = ''
      this.initAuction()
      window.smoothscroll()
    },
    reloadLots() {
      this.initAuction()
    },
    openConditions() {
      this.conditionsOpened = true
      window.smoothscroll()
    },
    closeConditions() {
      this.conditionsOpened = false
      window.smoothscroll()
    },
  },
}
</script>

<style lang="scss">
.loader {
  text-align: center;
  top: 200px;
  width: 50px;
  min-height: 500px;
  margin: auto;
  position: relative;
}

.button.live-button {
  width: 226px;
  margin: 9px 0;
  background: #363636;
  border: 2px solid #363636;
  box-sizing: border-box;
  color: #fff;
  transition: background-color 0.2s, color 0.2s;
}

.button.live-button:hover {
  background: #fff;
  color: #363636;
}

@media (min-width: 992px) {
  .right-wrapper {
    display: flex;
    flex-direction: row;
  }
}

.grid {
  margin: 0 1em;
}

.action-bar {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 1.2em;
}

.layout-switch {
  height: 51px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  text-align: right;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #000000;
  margin-left: auto;
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    width: calc(100% - 226px);
    margin: 9px 0 0 9px;
    padding: 0;
    display: grid;
    grid-template-columns: 100%;
    text-align: left;
    justify-content: flex-start;
  }
}

.layout-switch a {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #8b8b8b;

  @media (max-width: 1024px) {
    margin: 0;
    text-align: left;
  }
}

.col.col-66.cover-image img {
  height: 100%;
  width: 100%;
  max-height: 550px;
  object-fit: cover;
}

.additional__items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.lots__list {
  margin-top: 3em;
}

.ventes {
  padding: 0;
}

.ventes__refresh {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.ventes__refresh img {
  transition: transform 0.3s;
}

.ventes__refresh:hover img {
  transform: rotateZ(-90deg);
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.conditions {
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  padding: 17px 23px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  color: #000000;
}

.conditions .close {
  text-align: right;
}

@media (max-width: 1024px) {
  .col.col-66.cover-image img {
    height: auto;
    object-fit: none;
  }
}

@media (max-width: 767.98px) {
  .col.col-66.cover-image {
    display: none;
  }

  article.lot__item.col.col-33 {
    width: 50%;
  }

  .lots__buttons {
    display: none !important;
  }
}
</style>
