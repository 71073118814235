<template>
  <div>
    <div class="select__wrap" v-if="isMobile">
      <select
        class="select"
        :class="{ 'has-error': error }"
        :value="value"
        @input="select(arguments[0].target.value)"
      >
        <option :value="null" selected>{{ placeholder }}</option>
        <option :value="index" v-for="(item, index) in filteredResults">
          {{ getDropdownLabel(item) }}
        </option>
      </select>
    </div>

    <div
      class="select__wrap"
      v-if="!isMobile"
      :class="{ 'select__wrap--dropdown': expanded }"
    >
      <input
        type="text"
        class="select"
        :class="{ 'has-error': error }"
        @click="expanded = !expanded"
        :placeholder="placeholder"
        v-model="query"
      />
      <ul class="select__menu">
        <li class="select__item" :value="null">
          <a class="select__option" @click="select(null)">
            {{ allItemsLabel }}
          </a>
        </li>
        <li class="select__item" v-for="(item, index) in filteredResults">
          <a class="select__option link" @click="select(index)">
            {{ getDropdownLabel(item) }}
          </a>
        </li>
        <li class="select__item">
          <span class="select__option" v-if="hasError"
            >Sorry, no matching options.</span
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { isEmpty, get } from 'lodash'

export default {
  name: 'piasa-selector',
  props: {
    items: {
      default: () => [],
      type: Array,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => {
        return {}
      },
    },
    external_filtering: {
      type: Boolean,
      default: false,
    },
    labelKey: {
      type: String,
      default: 'name',
    },
    allItemsLabel: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: '',
      filteredResults: this.items,
      hasError: false,
      isMobile: false,
      expanded: false,
    }
  },
  mounted: function () {
    if (!isEmpty(this.value)) {
      this.query = this.value.translation.title
    }
  },
  methods: {
    select(index) {
      const item = get(this.items, index)

      this.query = item ? this.getDropdownLabel(item) : ''

      this.$emit('input', item)

      this.expanded = false
    },
    filter(query) {
      if (this.external_filtering) {
        this.$emit('search', query)
      } else {
        this.simpleFiltering(query)
      }
    },

    simpleFiltering(query) {
      this.filteredResults = this.items.filter((item) => {
        if (isEmpty(query)) {
          return true
        }

        const filter = query.toLowerCase()

        const name = item.translation.title.toLowerCase()

        return name.indexOf(filter) !== -1
      })
    },

    handleInput(input) {
      this.filteredResults = this.items.filter(
        (item) =>
          item.translation.title.toLowerCase().indexOf(input.toLowerCase()) !==
          -1
      )
    },

    getDropdownLabel(item) {
      return get(item, this.labelKey)
    },
  },
  watch: {
    items: function (items) {
      this.filteredResults = items
    },
    value: function (value) {
      this.query = get(value, this.labelKey)
    },
    query: function (query) {
      this.handleInput(query)
    },
  },
}
</script>
