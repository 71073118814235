<template>
  <div class="auction__bid" v-if="auction.data.live_streaming.current_lot_id">
    <div class="auction__bid-current">
      <div class="auction__bid-current-text">
        {{ $t('live.current_auction') }}
      </div>
      <div class="auction__bid-current-value">
        {{ bestBidAmount || auction.data.live_streaming.current_price }}
        {{ currency }}
      </div>
    </div>
    <div class="auction__bid-placer">
      <div class="auction__bid-placer-row">
        <span class="auction__bid-placer-text">{{ $t('live.quick_bid') }}</span>
        <button
          class="button auction__bid-placer-button"
          @click="makeBid(quickBidAmount)"
          :disabled="isBidButtonBlocked"
        >
          {{ quickBidAmount }} {{ currency }}
        </button>
      </div>
      <div class="auction__bid-placer-row">
        <span class="auction__bid-placer-text">{{ $t('live.make_bid') }}</span>
        <input
          class="form__input auction__bid-placer-input price_input"
          :class="{ 'has-error': $v.currentBid.$error }"
          type="text"
          maxlength="10"
          v-model="currentBid"
          @change="makeBid(currentBid)"
          :placeholder="$t('live.place_offer')"
          :disabled="isBidButtonBlocked"
        />
        <button
          class="button auction__submit-button"
          @click="makeBid(currentBid)"
          :disabled="!currentBid"
        >
          {{ $t('live.submit_bid') }}
        </button>
        <label
          v-if="!$v.currentBid.moreThan && $v.currentBid.maxValue"
          class="auction__bid-placer-error has-error"
        >
          {{ $t('live.low_bid_value_error') }} {{ currency }}
        </label>
        <label
          v-if="!$v.currentBid.maxValue"
          class="auction__bid-placer-error has-error"
        >
          {{ $t('live.high_bid_value_error') }} {{ currency }}
        </label>
        <label v-if="error" class="auction__bid-placer-error has-error">
          {{ error }}
        </label>
      </div>
    </div>
    <v-dialog />
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { ref, withParams } from 'vuelidate/lib/validators/common'
import maxValue from 'vuelidate/lib/validators/maxValue'
import BidsMixin from '../mixins/bids.mixin'

const moreThan = (equalTo) =>
  withParams({ type: 'sameAs', eq: equalTo }, function (value, parentVm) {
    return value > ref(equalTo, this, parentVm) || !value
  })

export default {
  name: 'client-bid-managing',
  props: ['auction', 'quickBidAmount', 'isBidButtonBlocked', 'lotId'],
  mixins: [validationMixin, BidsMixin],
  data() {
    return {
      currentBid: null,
      madeQuickBid: null,
      error: null,
    }
  },
  validations: {
    currentBid: {
      moreThan: moreThan('quickBidAmount'),
      maxValue: maxValue(1999999999),
    },
  },
  computed: {
    currency() {
      if (this.$route.name === 'app.client_auction') {
        return this.auction.id === 330 ? 'ZAR' : '€'
      }
      return '€'
    },
  },
  methods: {
    makeBid(bid) {
      if (!this.isBidButtonBlocked) {
        this.error = null

        this.$v.$touch()

        if (this.$v.$error) {
          return
        }

        if (
          this.$parent.bestBid &&
          this.$parent.user.id === this.$parent.bestBid.user_id
        ) {
          this.$modal.show('dialog', {
            text: this.$t('live.youAreLastBidder'),
            buttons: [
              {
                title: 'OK', // Button title
                default: true, // Will be triggered by default if 'Enter' pressed.
              },
            ],
          })
        } else {
          const self = this
          this.$api
            .createBid({
              user_id: this.$parent.user.id,
              lot_id: this.lotId,
              price: bid,
              type: 'online_bid',
            })
            .then(() => {
              self.currentBid = null
            })
            .catch((error) => {
              if (error.response) {
                self.error = error.response.data.error
              }
            })
          this.currentBid = null
        }
      }
    },
  },
}
</script>
