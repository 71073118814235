<template>
  <article :class="[!border ? 'vente--no-border' : '']" class="vente">
    <div class="row">
      <div class="col col-25">
        <img
          class="vente__img"
          :src="getThumbnail(auction.media[0].link)"
          :alt="auction.translation.title"
        />
      </div>
      <div class="col col-75">
        <div class="vente__wrap">
          <h2
            :class="[
              auction.type === 'AUCTION' ? 'vente__type--auction' : '',
              auction.type === 'ONLINE ONLY' ? 'vente__type--online' : '',
              auction.type === 'CHARITY AUCTION' ? 'vente__type--charity' : '',
              auction.type === 'ONLIVE' ? 'vente__type--onlive' : '',
            ]"
            class="vente__type"
          >
            {{ auction.type }}
          </h2>
          <h2
            class="vente__title vente__title--bold"
            v-html="auction.translation.title"
          ></h2>
          <ul class="vente__descr-list">
            <li class="vente__descr-item">
              <svg
                class="vente__descr-icon"
                width="16"
                height="19"
                viewBox="0 0 16 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 16.5625C0 17.5117 0.738281 18.25 1.6875 18.25H14.0625C14.9766 18.25 15.75 17.5117 15.75 16.5625V7H0V16.5625ZM11.25 9.67188C11.25 9.46094 11.4258 9.25 11.6719 9.25H13.0781C13.2891 9.25 13.5 9.46094 13.5 9.67188V11.0781C13.5 11.3242 13.2891 11.5 13.0781 11.5H11.6719C11.4258 11.5 11.25 11.3242 11.25 11.0781V9.67188ZM11.25 14.1719C11.25 13.9609 11.4258 13.75 11.6719 13.75H13.0781C13.2891 13.75 13.5 13.9609 13.5 14.1719V15.5781C13.5 15.8242 13.2891 16 13.0781 16H11.6719C11.4258 16 11.25 15.8242 11.25 15.5781V14.1719ZM6.75 9.67188C6.75 9.46094 6.92578 9.25 7.17188 9.25H8.57812C8.78906 9.25 9 9.46094 9 9.67188V11.0781C9 11.3242 8.78906 11.5 8.57812 11.5H7.17188C6.92578 11.5 6.75 11.3242 6.75 11.0781V9.67188ZM6.75 14.1719C6.75 13.9609 6.92578 13.75 7.17188 13.75H8.57812C8.78906 13.75 9 13.9609 9 14.1719V15.5781C9 15.8242 8.78906 16 8.57812 16H7.17188C6.92578 16 6.75 15.8242 6.75 15.5781V14.1719ZM2.25 9.67188C2.25 9.46094 2.42578 9.25 2.67188 9.25H4.07812C4.28906 9.25 4.5 9.46094 4.5 9.67188V11.0781C4.5 11.3242 4.28906 11.5 4.07812 11.5H2.67188C2.42578 11.5 2.25 11.3242 2.25 11.0781V9.67188ZM2.25 14.1719C2.25 13.9609 2.42578 13.75 2.67188 13.75H4.07812C4.28906 13.75 4.5 13.9609 4.5 14.1719V15.5781C4.5 15.8242 4.28906 16 4.07812 16H2.67188C2.42578 16 2.25 15.8242 2.25 15.5781V14.1719ZM14.0625 2.5H12.375V0.8125C12.375 0.53125 12.0938 0.25 11.8125 0.25H10.6875C10.3711 0.25 10.125 0.53125 10.125 0.8125V2.5H5.625V0.8125C5.625 0.53125 5.34375 0.25 5.0625 0.25H3.9375C3.62109 0.25 3.375 0.53125 3.375 0.8125V2.5H1.6875C0.738281 2.5 0 3.27344 0 4.1875V5.875H15.75V4.1875C15.75 3.27344 14.9766 2.5 14.0625 2.5Z"
                  fill="#8B8B8B"
                />
              </svg>
              <span>{{ auctionDate }}</span>
            </li>
            <li class="vente__descr-item" v-if="auction.location">
              <svg
                v-svg
                symbol="icon-map-marker"
                size="18 18"
                class="vente__descr-icon"
              ></svg>
              {{ auction.location }}
            </li>
          </ul>
          <div class="vente__footer">
            <div class="row">
              <div class="col vente__footer-wrap">
                <ul class="vente__info-list">
                  <li class="vente__info-item" v-if="isShowLosts">
                    <a :href="lotsUrl" class="link link--underline">
                      {{ $t('auctions.show_lots') }}
                    </a>
                  </li>
                  <li class="vente__info-item" v-else>
                    <router-link
                      class="link link--underline"
                      :to="{ name: 'app.auctions.details', params: { slug: auction.slug } }"
                    >
                      {{ $t('auctions.information') }}
                    </router-link>
                  </li>
                  <li class="vente__info-item" v-if="auction.free_catalog">
                    <a
                      :href="$http.getBackendUrl() + auction.free_catalog.link"
                      class="link link--underline"
                      target="_blank"
                    >
                      {{
                        isInPast()
                          ? $t('auctions.catalog_results')
                          : $t('auctions.catalog')
                      }}
                    </a>
                  </li>
                  <li class="vente__info-item">
                    <a
                      @click="$modal.show('auction-modal-' + auction.id)"
                      v-if="!isInPast()"
                      class="link link--underline"
                    >
                      {{ $t('auctions.calendar') }}
                    </a>
                  </li>
                  <li
                    v-if="needToShowLiveStreamingButton() && $store.state.token"
                    class="button participate-button"
                  >
                    <a :href="liveUrl">
                      {{ $t('auctions.item.button_live') }}
                    </a>
                  </li>
                  <li
                    v-else-if="
                      needToShowLiveStreamingButton() && !$store.state.token
                    "
                    class="button participate-button"
                  >
                    <router-link :to="getLiveAuctionRedirectData()">
                      {{ $t('auctions.register') }}
                    </router-link>
                  </li>
                  <li
                    v-if="needToShowOnlineBiddingButton() && $store.state.token"
                    class="button participate-button"
                  >
                    <router-link
                      v-if="subscribed(auction)"
                      :to="{
                        name: 'app.auctions.details',
                        params: { slug: auction.slug },
                      }"
                    >
                      {{ $t('auctions.item.button_bid') }}
                    </router-link>
                    <router-link
                      v-else
                      :to="{
                        name: 'app.client_auction_bidding',
                        params: { slug: auction.slug },
                      }"
                    >
                      {{ $t('auctions.item.button_bid') }}
                    </router-link>
                  </li>
                  <li
                    v-else-if="
                      needToShowOnlineBiddingButton() && !$store.state.token
                    "
                    class="button participate-button"
                  >
                    <router-link
                      :to="{
                        name: 'app.client_auction_bidding',
                        params: { slug: auction.slug },
                      }"
                    >
                      {{ $t('auctions.register') }}
                    </router-link>
                  </li>
                  <li
                    v-if="
                      needToShowLiveStreamingButton() && $store.state.is_admin
                    "
                    class="vente__info-item"
                  >
                    <router-link
                      :to="{
                        name: 'app.auction_slide',
                        params: { slug: auction.slug },
                      }"
                      class="link link--underline"
                    >
                      Show Screen
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <auction-modal :auction="auction" :width="600" />
  </article>
</template>

<script>
import { isEmpty } from 'lodash'
import { getLocale } from '@/services/i18n.service'
import AuctionModal from './AuctionModal'
import AuctionStatusMixin from './../mixins/auctionStatus.mixin'

export default {
  name: 'auction-item',
  components: { AuctionModal },
  mixins: [AuctionStatusMixin],
  props: {
    auction: {
      type: Object,
      required: true,
    },
    border: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    userNotAuthenticated() {
      return isEmpty(this.$store.state.user)
    },
    auctionDate() {
      const startedAt = this.moment(this.auction.started_at).format(
        'dddd D MMMM HH:mm'
      )

      return this.auction.finished_at && this.auction.show_ending_date
        ? this.$t('auctions.item.date', {
            startDate: startedAt,
            endDate: this.moment(this.auction.finished_at).format(
              'dddd D MMMM HH:mm'
            ),
          })
        : startedAt
    },
    isShowLosts() {
      return (
        this.auction.lots_count ||
        (this.auction.drouot_auction &&
          (this.auction.lots_count ||
            !isEmpty(this.auction.drouot_auction_url)))
      )
    },
    lotsUrl() {
      if (
        this.auction.drouot_auction &&
        !isEmpty(this.auction.drouot_auction_url)
      ) {
        return this.auction.drouot_auction_url
      } else {
        return (
          '/' + getLocale() + '/auctions/' + this.auction.slug + '#lots-list'
        )
      }
    },
    liveUrl() {
      return '/live/auctions/' + this.auction.id + '/live';
    },
  },
  methods: {
    isInPast() {
      return (
        this.auction.finished_at &&
        this.moment(this.auction.finished_at).isBefore()
      )
    },
    getBoughtCatalog() {
      if (this.auction.is_paid_catalog_bought) {
        return
      }

      const self = this

      self.$api.createCatalogOrder(self.auction.id).then((response) => {
        self.$router.push({
          name: 'app.orders.payment',
          params: {
            orderId: response.data.id,
          },
        })
      })
    },
    subscribed(item) {
      if (!this.userNotAuthenticated) {
        return this.$store.state.user.auction_subscriptions.some(
          (auction) => item.id === auction.id
        )
      }
      return false
    },
  },
}
</script>

<style scoped>
.auction-status {
  top: -10px;
}

h2.vente__type.auction {
  align-self: flex-start;
  padding: 0.5em 2em;
  background: #fef6e9;
  color: #f5a623;
  font-size: 14px;
}

li.button.participate-button {
  width: 226px;
  height: 51px;
  position: absolute;
  right: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 46px;
  text-align: center;
  color: #000000;
  padding: 0;
}

li.button.participate-button > a {
  width: 100%;
  height: 100%;
  display: block;
}

li.button.participate-button:hover {
  transition: all 0.1s;
  color: #fff;
  background-color: #363636;
  outline: none;
}

@media (max-width: 1024px) {
  li.button.participate-button {
    position: unset;
    margin-bottom: 10px;
  }
}
</style>
